import { useState, useRef, useEffect } from "react";
import useKeypress from "react-use-keypress";
import useInterval from "./assets/hooks/Interval";
import useResetableState from './assets/hooks/ResetableState';

import Board from "./Board";
import Controls from "./Controls";
import PreView from "./assets/Preview";
import PopUpPause from "./assets/PopUpPause";
import PopUpLoos from "./assets/PopUpLoos";
import AdsComponent from './ad';

const _pieces = {
    I: "I",
    J: "J",
    L: "L",
    O: "O",
    S: "S",
    T: "T",
    Z: "Z"
}

const _shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array
} 

const _piecesTemplate = {
    I: [{x: 4, y: 21}, {x: 5, y: 21}, {x: 6, y: 21}, {x: 7, y: 21}],
    J: [{x: 4, y: 21}, {x: 5, y: 21}, {x: 6, y: 21}, {x: 4, y: 22}],
    L: [{x: 4, y: 21}, {x: 5, y: 21}, {x: 6, y: 21}, {x: 6, y: 22}],
    O: [{x: 5, y: 21}, {x: 6, y: 21}, {x: 5, y: 22}, {x: 6, y: 22}],
    S: [{x: 4, y: 21}, {x: 5, y: 21}, {x: 5, y: 22}, {x: 6, y: 22}],
    T: [{x: 5, y: 22}, {x: 4, y: 21}, {x: 5, y: 21}, {x: 6, y: 21}],
    Z: [{x: 5, y: 21}, {x: 6, y: 21}, {x: 4, y: 22}, {x: 5, y: 22}]
};

const _piecesRotationOffset = { 
    /*
        To prevent pieces drifting while rotating, an ofset will be added to each postion. [0] of each piece's ofset will be be applied from north to east, [1] for east to south, etc.
    */
    I: [{x: 0, y: -1}, {x: 0, y: -1}, {x: -1, y: 0}, {x: 1, y: 0}],
    J: [{x: 1, y: -1}, {x: -1, y: -1},  {x: 0, y: 0}, {x: 0, y: 0}],
    L: [{x: 1, y: -1}, {x: -1, y: -1}, {x: 0, y: 0}, {x: 0, y: 0}],
    O: [{x: 0, y: -1}, {x: 0, y: -1}, {x: 0, y: -1}, {x: 0, y: -1}],
    S: [{x: 1, y: -1}, {x: -1, y: -1}, {x: 0, y: 0}, {x: 0, y: 0}],
    T: [{x: 1, y: -1}, {x: -1, y: -1},  {x: 0, y: 0}, {x: 0, y: 0}],
    Z: [{x: 1, y: -1}, {x: -1, y: -1}, {x: 0, y: 0}, {x: 0, y: 0}]
} 

const _getKeyFor = (x, y) => {
    return `${x}-${y}`
}

function* _getId(){
    for(let i = 0; true; i++){
        yield i
    }
}

const _genID = _getId()

function Game({moreRowsCleared, level, isSessionStarted, handleStart, preRenderedScoreboard}) {

    // Game Data
    const [matrix, setMatrix] = useState({})

    const [movingBlocks, setMovingBlocks] = useState([])

    const orientationMovingPiece = useRef() // Will be used to apply the correct offset. Value equals to index in _piecesRoationOffset 
    
    const ghostBlocks = useRef([]);

    const [nextBlocks, setNextBlocks] = useState([])
    const [heldBlocks, setHeldBlocks] = useState([])    

    const [activePiece, setActivePiece] = useState([])
    const [heldPiece, setHeldPiece] = useState([])
    
    const _bag = useRef([]) // Ensures correct drawing order for new moving blocks

    // Game Controls
    const [isGameActive, setIsGameActive] = useState(false); //Should pieces Drop? 
    const [isGameOver, setIsGameOver] = useState(false) //Has the player lost? 
    
    //Stats
    const piecesPlaced = useRef(0) 
    
    // Game Speed Control
    const [dropSpeed, setDropSpeed, resetDropSpeed] = useResetableState(1000/(1+(1-Math.pow(0.8 - ((level-1) * 0.007)), level-1))) //offical forumla to calculate the drop speed in ms for each level
    
    const isRapidDescentMode = useRef(false);
    const rapidDescentSpeed = 0.1;
    const rapidDescentGracePeriod = 300;
    
    // Initaliztion
    useEffect(() => {
        if (isSessionStarted){
            setIsGameActive(true)
            _newBag()
            _newDrop(_getNextPiece())
        }
    }, [ isSessionStarted ])

    //Automatic Movement

    useInterval(() => { // Moving blocks drops at drop speed; i.e. will be moved down one row
        const nextPos = movingBlocks.map((block, _) => { //update pos of all blocks
            return {
            ...block, // other block data remains unchanged
            pos: { x: block.pos.x, y: block.pos.y - 1 } // one row down
            };
        });

        if (!_isValidPos(nextPos)) { //The block can't move further

            if (isRapidDescentMode.current) { //in rapid descent mode, the user gets the chance for some last adjustments. 
                setDropSpeed(rapidDescentGracePeriod)
                isRapidDescentMode.current = false 
            }
            else{
                resetDropSpeed() //disable grace period
                _stopDrop()
            }
        }
        else { 
            _updateMovingBlocks(nextPos) //The block moves further down
        }
    }, dropSpeed , isGameActive);

    // Logic

    const _stopDrop = () => {  //The moving blocks are converted to static blocks and new moving blocks are initialized
        _addStaticBlocks(movingBlocks);

        ghostBlocks.current = [] // clean up
        _newDrop(_getNextPiece()) //new moving piece
    };

    const _addStaticBlocks = (blocks) => { 
        const _updatedYs = [] //Impacted rows which could have potenially been filled by the new static blocks 
        const nextMatrix = {...matrix};

        blocks.forEach((block, _) => {
            let newBlock = _createBlock(block.piece, _genID.next().value, block.pos.x, block.pos.y) 

            if (!_isStaticBlockValid(newBlock.pos.y)){ //Block would need to be placed in an illegal spot.
                _stopGame()
                setIsGameOver(true)
                return
            }
            
            else if (!_updatedYs.includes(newBlock.pos.y)) { //track all rows changed, reduces the complextiy needed for checking if a row has been filled 
                _updatedYs.push(newBlock.pos.y)
            }

            nextMatrix[_getKeyFor(newBlock.pos.x, newBlock.pos.y)] = newBlock
        }
        )

        const rowsToClear = _updatedYs.filter((row, _) => _isRowFull(row, nextMatrix)) //check if rows have been filled
        if (rowsToClear.length !== 0){
            _clearRows(rowsToClear, nextMatrix) //removel is handeld in place to not undo changes in not filled up rows.
        }

        piecesPlaced.current++

        setMatrix(nextMatrix)
        moreRowsCleared(rowsToClear.length) //stats, handled by App
    };

    const _getNextPiece = () => {
        const piece = _bag.current.pop() // piece is pulled from bag

        // Bag maintance
        if (_bag.current.length === 0){ //refill bag if needed
            _newBag()
        }

        return piece
    }

    const _newDrop = (piece) => { // new moving piece

        const nextPiece = _bag.current[_bag.current.length-1] // The last piece in the (potenitally new) bag will be pulled in the next round.
        setActivePiece(piece)

        const blocks = _createMovingPiece(piece)
        const nextBlocks = _createPreViewPiece(nextPiece)
        
        setMovingBlocks(blocks)
        setNextBlocks(nextBlocks)

        orientationMovingPiece.current = 0 //start: north
    };

    const _clearRows = (ys, nextMatrix) => { // ys as the plural of y

        // 1. delete all fields in the y rows

        ys.forEach((y, _) => {
            for (let x = 1; x <= 10; x++){
                delete nextMatrix[_getKeyFor(x, y)]
            }
        })

        // 2. fill the gap
        
        for (let y = Math.min.apply(Math, ys) + 1; y <= 20; y++){

                if (!ys.includes(y)){ // ignore emptied rows
                const step = -ys.filter((oldY,_) => oldY < y).length

                for (let x = 1; x <= 10; x++){
                    const key = _getKeyFor(x, y)
                    const nextKey = _getKeyFor(x, y+step)

                    if (nextMatrix[key]){

                        nextMatrix[nextKey] = {
                            ...nextMatrix[key],
                            pos: {x: nextMatrix[key].pos.x, y: nextMatrix[key].pos.y+step}
                        }
                        delete nextMatrix[key]
                    }
                }
            }
        }
    }

    // Logic Helpers

    const _createBlock = (piece, id, x, y) => {
        return {piece: piece, id: id, pos: {x: x, y: y}}
    };

    const _newBag = () => {
        _bag.current = _shuffleArray(Object.values(_pieces))
    }

    const _createMovingPiece = (piece) => {
        return _piecesTemplate[piece].map((temp, i) => _createBlock(piece, `m-${i}`, temp.x, temp.y));
    }

    const _createPreViewPiece = (piece) => {
        return _piecesTemplate[piece].map((temp, i) => _createBlock(piece, `p-${i}`, temp.x, temp.y));
    }

    const _getBlocksAtLowestY = (blocks) => { // determine pos for ghost piece
        let minDistToYEdge = Infinity //The distance between the block closest to a static block on the y axis and that block 
        blocks.forEach((block, _) => {
            const distToYEdge = block.pos.y - _getLowestY(block.pos.x, block.pos.y);
            if (distToYEdge < minDistToYEdge) {
                minDistToYEdge = distToYEdge;
            };
        })
    
        return Array.from(blocks, (block, i) => _createBlock(block.piece, `g-${i}`, block.pos.x, (block.pos.y - minDistToYEdge + 1))) //set the y of all blocks to one above the nearest static block
    }; 

    const _updateMovingBlocks = (nextPos) => {
        ghostBlocks.current = _getBlocksAtLowestY(nextPos)
        setMovingBlocks(nextPos);
    }

    const _isStaticBlockValid = (y) => { 
        return y <=20
    }

    const _isValidPos = (blocks) => { //movement into occupied cells, the walls or the floor is illegal
        return blocks.every((block, _) => (_isFieldEmpty(block.pos.x, block.pos.y) && block.pos.y >= 1 && block.pos.x <= 10 && block.pos.x >= 1))
    };
    
    const _isFieldEmpty = (x, y, nextMatrix) => {
        const object = nextMatrix ?? matrix

        return !(object[_getKeyFor(x, y)])
    };

    const _isRowFull = (y, nextMatrix) => {
        for (let x = 1; x <= 10; x++){
            if (_isFieldEmpty(x, y, nextMatrix)){
                return false
            }
        }

        return true
    }

    const _getLowestY = (x, y) => { // lowest occupied field in a column (highest y value) in a column below the y parameter 
        for (let checkY = y; checkY >= 1; checkY--) { //start at y and move down
            if (!_isFieldEmpty(x, checkY)){
                return checkY
            }
        }
        return 0
    };

    const _stopGame = () => {
        setIsGameActive(false)
    }

    //Manual Movement

    const _rapidDescent = () => {
        isRapidDescentMode.current = true
        setDropSpeed(rapidDescentSpeed)
    }
    
    const _movePiece = (left, right, down) => { // called by player to control the pieces position.
        
        if (!isGameActive){
            return
        }

        let nextPos = [...movingBlocks]

        if (left){
            nextPos = nextPos.map((block, _) => {return {
                ...block,
                pos: {x: block.pos.x-1, y: block.pos.y}
            }})
        }
        else if (right){
            nextPos = nextPos.map((block, _) => {return {
                ...block,
                pos: {x: block.pos.x+1, y: block.pos.y}
            }})
        }
        else if (down){
            nextPos = nextPos.map((block, _) => {return {
                ...block,
                pos: {x: block.pos.x, y: block.pos.y-1}
            }})
        }

        if (_isValidPos(nextPos)){
            _updateMovingBlocks(nextPos)
        }
    }

    const _rotatePiece = () => { // called by player
        
        if (!isGameActive){
            return
        }

        const offset = _piecesRotationOffset[movingBlocks[0].piece][orientationMovingPiece.current]
        let nextPos = [...movingBlocks]

        //1. determine center
        
        let scaleX = [Infinity, -Infinity];
        let scaleY = [Infinity, -Infinity];  

        nextPos.forEach((block, _)=> {
            if (block.pos.x < scaleX[0]) {
                scaleX[0] = block.pos.x;
            }
            if (block.pos.x > scaleX[1]) {
                scaleX[1] = block.pos.x;
            }
            if (block.pos.y < scaleY[0]) {
                scaleY[0] = block.pos.y;
            }
            if (block.pos.y > scaleY[1]) {
                scaleY[1] = block.pos.y;
            }
        })
        
        const centerX = Math.ceil((scaleX[0] + scaleX[1]) / 2);
        const centerY = Math.ceil((scaleY[0] + scaleY[1]) / 2);

        const orignToAxis = {x: centerX, y: centerY}

        //2. Turn piece by 90° and add offset

        nextPos = nextPos.map((block, _) => {

            const axisToPoint = {x: block.pos.x - centerX, y: block.pos.y - centerY}

            let [nextX, nextY] = _rotateBlock(orignToAxis, axisToPoint, 90)

            nextX = nextX + offset.x
            nextY = nextY + offset.y
            
            return {
                ...block,
                pos: {x: nextX, y: nextY}
            }
        })

        if (_isValidPos(nextPos)){
            _updateMovingBlocks(nextPos)
            orientationMovingPiece.current < 3 ? orientationMovingPiece.current++: orientationMovingPiece.current = 0 // max orientation is 3 (west) 0 is north
        }
    }

    //Movement helpers
    
    const _rotateBlock = (orignToAxis, axisToPoint, angle) => {
        const cos = 0
        const sin = -1
        
        // Apply the matrix transformation
        const nextX = cos * axisToPoint.x - sin * axisToPoint.y + orignToAxis.x;
        const nextY = sin * axisToPoint.x + cos * axisToPoint.y + orignToAxis.y;

        return [nextX, nextY];
    }

    //Holding management

    const _holdPiece = () => {      
        const piece = heldPiece
        ghostBlocks.current = [] // clean up
               
        if (piece.length !== 0){ //piece in holding bay
            _newDrop(piece) //new moving piece
        }
        else { //no piece in holding bay
            _newDrop(_getNextPiece())
        }

        console.log(activePiece);

        setHeldBlocks(_createPreViewPiece(activePiece))
        setHeldPiece(activePiece)
    }

    //Control
    
    const handleRight = (e) => {
        e.preventDefault();
        _movePiece(false, true, false)
    }
    const handleLeft = (e) => {
        e.preventDefault();
        _movePiece(true, false, false)
    }
    const handleDown = (e) => {
        e.preventDefault();
        _movePiece(false, false, true)
    }
    const handleRotate = (e) => {
        e.preventDefault()
        _rotatePiece()
    }

    const handleStop = (e) => {
        e.preventDefault();
        _stopGame()
    }

    const handleRapidDescent = (e) => {
        e.preventDefault();
        _rapidDescent()
    }

    const handldHoldingPieceManagement = (e) => {
        _holdPiece()
    }

    // Keystroke detection

    useKeypress('ArrowLeft', handleLeft)
    useKeypress('ArrowRight', handleRight)
    useKeypress('ArrowDown', handleDown)
    useKeypress('ArrowUp', handleRotate)
    useKeypress('Escape', () => setIsGameActive(!isGameActive))
    useKeypress('Shift', handleRapidDescent)
    useKeypress('-', handldHoldingPieceManagement)

    return (
        <>
            {
                !isGameActive & isSessionStarted ? ( //if session is not in progress, the player either has Lost or has paused  
                    isGameOver ? <PopUpLoos handleClick={handleStart} />: <PopUpPause handlePause={() => setIsGameActive(!isGameActive)} handleRestart={handleStart}/>
                ): null
            }
            <div className="boardContainer">
                <AdsComponent dataAdSlot="ad1"/>
                {preRenderedScoreboard}
                <Board 
                    matrix={matrix}
                    movingBlocks={movingBlocks}
                    ghostBlocks={ghostBlocks.current}
                />
                <div className="preViewContainer">
                    <p>Coming up</p>
                    <PreView
                        blocks={nextBlocks}
                    />
                    <p>Held</p>
                    <PreView
                        blocks={heldBlocks}
                    />
                </div>
                <AdsComponent dataAdSlot="ad2"/>
            </div>
            <Controls
                onRight={handleRight}
                onLeft={handleLeft}
                onDown={handleDown}
                onRotate={handleRotate}
                onStart={handleStart} //is handled by Game (handleStart is a prop)
                onStop={handleStop}
                onRapidDescent={handleRapidDescent}
                isGameActive={isGameActive}
            />
        </>
    );
  }
  
export default Game;

  
