const AdsComponent = ({dataAdSlot}) => {    
    return (
        <div style={{gridArea: dataAdSlot, width: '200px', height: '500px'}}>
        <ins className="adsbygoogle"
            style={{display: 'inline-block', width:'200px', height: '500px'}}
            data-ad-client="ca-pub-2858929713276652"
            data-ad-slot="5172761627"></ins>
        </div>
    );
};

export default AdsComponent;